import { MenuPageHeader, CurrentMenu } from '../../components'
import { images } from '../../data'

const BeerMenus = () => {

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-16 mx-3 md:mt-0 md:mx-6">
          <MenuPageHeader img="bg-beer" title="BEER" position="bg-center" />
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-2 mt-1">
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.beer_menu_leesburg}
                headerTitle="Leesburg"
                imgTitle="Leesburg Beer Menu"
                downloadLink='beer_menu_leesburg.png'
                path="/leesburgbeereditor"
                qrcode="https://menumodx.com/static/media/beer_menu_leesburg.d6961f9f7fcd7beca8e2.png"
                qrLink="menu_leesburg_beer_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.beer_menu_middleburg}
                headerTitle="Middleburg"
                imgTitle="Middleburg Beer Menu"
                downloadLink='beer_menu_middleburg.png'
                path="/middleburgbeereditor"
                qrcode="https://menumodx.com/static/media/beer_menu_middleburg.c7c87f5b4bfd54941fa9.png"
                qrLink="menu_middleburg_beer_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.beer_menu_noma}
                headerTitle="NoMa"
                imgTitle="NoMa Beer Menu"
                downloadLink='beer_menu_noma.png'
                path="/nomabeereditor"
                qrcode="https://menumodx.com/static/media/beer_menu_noma.5175f609b95669ba5dc6.png"
                qrLink="menu_noma_beer_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.beer_menu_potomac}
                headerTitle="Potomac"
                imgTitle="Potomac Beer Menu"
                downloadLink='beer_menu_potomac.png'
                path="/potomacbeereditor"
                qrcode="https://menumodx.com/static/media/beer_menu_potomac.9a38bf82a226ffa630c5.png"
                qrLink="menu_potomac_beer_qrCode.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BeerMenus