import { MenuPageHeader, CurrentMenu } from '../../components'
import { images } from '../../data'

const OysterMenus = () => {

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-16 mx-3 md:mt-0 md:mx-6">
          <MenuPageHeader img="bg-oysters2" title="OYSTERS" position="bg-center" />
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-2 mt-1">
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.oyster_menu_leesburg}
                headerTitle="Leesburg"
                imgTitle="Leesburg Oyster Menu"
                downloadLink='oyster_menu_leesburg.png'
                path="/leesburgoystereditor"
                qrcode="https://menumodx.com/static/media/oyster_menu_leesburg.4a67d02f781f368189bf.png"
                qrLink="menu_leesburg_oyster_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.oyster_menu_middleburg}
                headerTitle="Middleburg"
                imgTitle="Middleburg Oyster Menu"
                downloadLink='oyster_menu_middleburg.png'
                path="/middleburgoystereditor"
                qrcode="https://menumodx.com/static/media/oyster_menu_middleburg.95d1995ce7a1cb26d20a.png"
                qrLink="menu_middleburg_oyster_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.oyster_menu_noma}
                headerTitle="NoMa"
                imgTitle="NoMa Oyster Menu"
                downloadLink='oyster_menu_noma.png'
                path="/nomaoystereditor"
                qrcode="https://menumodx.com/static/media/oyster_menu_noma.d0127a3834492a1201f1.png"
                qrLink="menu_noma_oyster_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.oyster_menu_potomac}
                headerTitle="Potomac"
                imgTitle="Potomac Oyster Menu"
                downloadLink='oyster_menu_potomac.png'
                path="/potomacoystereditor"
                qrcode="https://menumodx.com/static/media/oyster_menu_potomac.d8295f8de8156ca00356.png"
                qrLink="menu_potomac_oyster_qrCode.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OysterMenus