import { MenuPageHeader, CurrentMenu } from '../../components'
import { images } from '../../data'

const DinnerMenus = () => {

  return (
    <>
      <div className="flex justify-center md:mx-24">
        <div className="mt-16 mx-3 md:mt-0 md:mx-8">
          <MenuPageHeader img="bg-dinner" title="DINNER" position="bg-center" />
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-2 mt-1">
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.leesburg_dinner_menu}
                headerTitle="Leesburg"
                imgTitle="Leesburg Dinner Menu"
                downloadLink='leesburg-dinner-menu.png'
                path="/leesburgdinnereditor"
                qrcode="https://menumodx.com/static/media/leesburg_dinner_menu.e09d0d86f861c0b2fd85.jpg"
                qrLink="menu_leesburg_dinner_qrCode.png"
              />

            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.middleburg_dinner_menu}
                headerTitle="Middleburg"
                imgTitle="Middleburg Dinner Menu"
                downloadLink='middleburg-dinner-menu.png'
                path="/middleburgdinnereditor"
                qrcode="https://menumodx.com/static/media/middleburg_dinner_menu.58b6dfa3614cee5cf7ff.jpg"
                qrLink="menu_pmiddleburg_dinner_qrCode.png"
              />

            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.noma_dinner_menu}
                headerTitle="NoMa"
                imgTitle="NoMa Dinner Menu"
                downloadLink='noma-dinner-menu.png'
                path="/nomadinnereditor"
                qrcode="https://www.kingstreetoysterbar.com/washington-dc/menus/dinner/"
                qrLink="menu_noma_dinner_qrCode.png"
              />

            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.potomac_dinner_menu}
                headerTitle="Potomac"
                imgTitle="Potomac Dinner Menu"
                downloadLink='potomac-dinner-menu.png'
                path="/potomacdinnereditor"
                qrcode="https://menumodx.com/static/media/potomac_dinner_menu.3606c86f73233663ed5b.jpg"
                qrLink="menu_potomac_dinner_qrCode.png"
              />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DinnerMenus