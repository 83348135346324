import { useCallback, useRef, useState } from 'react'
import { ContainerHeader, DownloadBtn, EditBtn } from '../'
import { toPng } from 'html-to-image'
import { Link } from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo'
import { useStateContext } from "../../contexts/ContextProvider"
import { images } from '../../data'

const CurrentMenu = ({ downloadLink, img, headerTitle, imgTitle, path, qrcode, qrLink }) => {
    const { currentColor } = useStateContext()

    const ref = useRef(null);
    const ref2 = useRef(null);


    const [effect, setEffect] = useState(false)

    function toggleEffect() {
        setEffect(!effect)
    }

    const onButtonClick = useCallback(() => {
        if (ref.current === null) {
            return
        }

        toPng(ref.current, { cacheBust: true, pixelRatio: 10, width: 384, height: 576 })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = downloadLink
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const downloadQR = useCallback(() => {
        if (ref2.current === null) {
            return
        }
        toPng(ref2.current, { cacheBust: true, pixelRatio: 10 })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = qrLink
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <div className="md:max-w-300">
                <ContainerHeader title={headerTitle} />
                <img
                    className={`${effect && "animate-enlarge175"} border-b border-gray-600 hover:cursor-pointer`}
                    onClick={toggleEffect}
                    src={img}
                    ref={ref}
                    alt={imgTitle}
                    title={imgTitle}
                    loading="lazy" />
                <div
                    className="text-gray-400 border-b border-gray-600 rounded-b-xl p-3"
                    style={{ backgroundImage: `linear-gradient(to top, #191919, #2f2f2f` }}>
                    <div className="flex flex-wrap justify-center lg:flex-nowrap">
                        <DownloadBtn onClick={onButtonClick} />
                        <Link to={path}>
                            <EditBtn />
                        </Link>
                    </div>
                    <div className="flex justify-center m-4">
                        <div ref={ref2} >
                            <QRCode
                                value={qrcode}
                                id="qr"
                                size={230}
                                fgColor="#1b2e3d"
                                eyeRadius={10}
                                logoImage={images.logo}
                                logoWidth={50}
                                logoHeight={50}
                                logoPadding={2}
                                logoPaddingStyle="circle"
                                removeQrCodeBehindLogo={true}
                                quietZone={20}
                                qrStyle="dots"
                            />
                        </div>
                    </div>
                    <div className="text-gray-200 flex justify-center">
                        <button
                            className="border border-gray-400 rounded-lg hover:drop-shadow-2xl hover:scale-105 active:scale-95 p-2"
                            style={{ backgroundImage: `linear-gradient(to top, #191919, ${currentColor})` }}>
                            <a onClick={downloadQR} >Download QR</a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CurrentMenu