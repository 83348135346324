import { MenuPageHeader, CurrentMenu } from '../../components'
import { images } from '../../data'

const HappyHourMenus = () => {

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-16 mx-3 md:mt-0 md:mx-6">
          <MenuPageHeader img="bg-hh" title="HAPPY HOUR" position="bg-center" />
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-2 mt-1">
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.happy_hour_menu_leesburg}
                headerTitle="Leesburg"
                imgTitle="Leesburg Happy Hour Menu"
                downloadLink='happy_hour_menu_leesburg.png'
                path="/leesburghappyhoureditor"
                qrcode="https://menumodx.com/static/media/happy_hour_menu_leesburg.a3fe20cafa373a77d76e.png"
                qrLink="menu_leesburg_happyhour_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.happy_hour_menu_middleburg}
                headerTitle="Middleburg"
                imgTitle="Middleburg Happy Hour Menu"
                downloadLink='happy_hour_menu_middleburg.png'
                path="/middleburghappyhoureditor"
                qrcode="https://menumodx.com/static/media/happy_hour_menu_middleburg.c9bd12e87d88af48d8b1.png"
                qrLink="menu_middleburg_happyhour_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.happy_hour_menu_noma}
                headerTitle="NoMa"
                imgTitle="NoMa Happy Hour Menu"
                downloadLink='happy_hour_menu_noma.png'
                path="/nomahappyhoureditor"
                qrcode="https://menumodx.com/static/media/happy_hour_menu_noma.bc2c4159054df1c29b05.png"
                qrLink="menu_noma_happyhour_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.happy_hour_menu_potomac}
                headerTitle="Potomac"
                imgTitle="Potomac Happy Hour Menu"
                downloadLink='happy_hour_menu_potomac.png'
                path="/potomachappyhoureditor"
                qrcode="https://menumodx.com/static/media/happy_hour_menu_potomac.a447321e287d991f1e9d.png"
                qrLink="menu_potomac_happyhour_qrCode.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HappyHourMenus