import { MenuPageHeader, CurrentMenu } from '../../components'
import { images } from '../../data'

const BrunchMenus = () => {

  return (
    <>
      <div className="flex justify-center md:mx-24">
        <div className="mt-16 mx-3 md:mt-0 md:mx-8">
          <MenuPageHeader img="bg-brunch" title="BRUNCH" position="bg-center" />
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-2 mt-1">
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.leesburg_brunch_menu}
                headerTitle="Leesburg"
                imgTitle="Leesburg Brunch Menu"
                downloadLink='leesburg-brunch-menu.png'
                path="/leesburgbruncheditor"
                qrcode="https://menumodx.com/static/media/leesburg_brunch_menu.1c0ef20c5404fc2f3f80.jpg"
                qrLink="menu_leesburg_brunch_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.middleburg_brunch_menu}
                headerTitle="Middleburg"
                imgTitle="Middleburg Brunch Menu"
                downloadLink='middleburg-brunch-menu.png'
                path="/middleburgbruncheditor"
                qrcode="https://menumodx.com/static/media/middleburg_brunch_menu.f6425589106f06d1a462.jpg"
                qrLink="menu_middleburg_brunch_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.noma_brunch_menu}
                headerTitle="NoMa"
                imgTitle="NoMa Brunch Menu"
                downloadLink='noma-brunch-menu.png'
                path="/nomabruncheditor"
                qrcode="https://www.kingstreetoysterbar.com/washington-dc/menus/brunch/"
                qrLink="menu_noma_brunch_qrCode.png"
              />
            </div>
            <div className="md:max-w-500 w-full">
              <CurrentMenu
                img={images.potomac_brunch_menu}
                headerTitle="Potomac"
                imgTitle="Potomac Brunch Menu"
                downloadLink='potomac-brunch-menu.png'
                path="/potomacbruncheditor"
                qrcode="https://menumodx.com/static/media/potomac_brunch_menu.5620316092ef96642682.jpg"
                qrLink="menu_potomac_brunch_qrCode.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrunchMenus